// export const url = 'http://127.0.0.1:8000/'
// export const socket = "ws://127.0.0.1:8000/"
// export const fileUrl = 'http://127.0.0.1:8000'
// export const mediaUrl = 'http://127.0.0.1:8000/media/'

export const url = 'https://api.moneybugs.in/'
export const socket = "wss://api.moneybugs.in/"
export const fileUrl = 'https://api.moneybugs.in'
export const mediaUrl = 'https://api.moneybugs.in/media/'

const teleToken = '403e3f99879cfe75a89ffa53a4b7ccb7e87d5eaefa8d0d136655e9d9a111a240'

export const tinyApiKey = "hr83zbvspkz7lpfrc5wpxvua1v3c3hblwjczvs1joy24a1gz"

export function numFormatter(number) {
    number = Number(number)
    if (typeof number !== "number") return number; // Return as is if not a number
    if (Number.isInteger(number)) {
        return number
    }
    // Convert the number to two decimal places
    const fixedNumber = number.toFixed(2);

    // Split the integer and decimal parts
    const [integerPart, decimalPart] = fixedNumber.split(".");
    const lastThreeDigits = integerPart.slice(-3);
    const otherDigits = integerPart.slice(0, -3);

    // Format the integer part with commas in the Indian system
    const formattedInteger =
        otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
        (otherDigits ? "," : "") +
        lastThreeDigits;

    // Combine the formatted integer part and the decimal part
    return `${formattedInteger}.${decimalPart}`;
}
// export function numFormatter(num) {
//     if (num) {
//         if (num > 999 && num < 1000000) {
//             return (num / 1000).toFixed(1) + ' K'; // convert to K for number from > 1000 < 1 million 
//         } else if (num > 1000000) {
//             return (num / 1000000).toFixed(1) + ' M'; // convert to M for number from > 1 million 
//         } else if (num < 1000) {
//             return num; // if value < 1000, nothing to do
//         }
//     } else {
//         return 0;
//     }
// }
export function numFormatter2(number) {
    if (typeof number !== "number") return number; // Return as is if not a number
    if (Number.isInteger(number)) {
        return number
    }
    // Convert the number to two decimal places
    const fixedNumber = number.toFixed(2);

    // Split the integer and decimal parts
    const [integerPart, decimalPart] = fixedNumber.split(".");
    const lastThreeDigits = integerPart.slice(-3);
    const otherDigits = integerPart.slice(0, -3);

    // Format the integer part with commas in the Indian system
    const formattedInteger =
        otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
        (otherDigits ? "," : "") +
        lastThreeDigits;

    // Combine the formatted integer part and the decimal part
    return `${formattedInteger}.${decimalPart}`;
}

export const leadFollowUpOptions = [
    { value: 'INT', label: 'Interested' },
    { value: 'N-INT', label: 'Not Interested' },
    { value: 'CB', label: 'Call Back' },
    { value: 'DND', label: 'DND' },
    { value: 'RNR', label: 'Ring Not Recieved' },
    { value: 'OOS', label: 'Out of Service' },
    { value: 'SO', label: 'Switch Off' },
    { value: 'PPT', label: 'PPT' },
]
export const leadFollowUpOptions2 = [
    { value: 'INT', label: 'Interested' },
    { value: 'N-INT', label: 'Not Interested' },
    { value: 'CB', label: 'Call Back' },
    { value: 'DND', label: 'DND' },
    { value: 'RNR', label: 'Ring Not Recieved' },
    { value: 'OOS', label: 'Out of Service' },
    { value: 'SO', label: 'Switch Off' },
    { value: 'PPT', label: 'PPT' },
    { value: 'LEAD', label: 'Lead' },
]

export const userType = {
    admin: 'ADMIN',
    floor_manager: 'FLOOR MANAGER',
    sales_executive: 'SALES EXECUTIVE',
    team_leader: 'TEAM LEADER',
    tso: 'TSO',
    operation_manager: 'OPERATION MANAGER',
    renewal_coordinator: 'RENEWAL COORDINATOR',
    service_support: 'SERVICE SUPPORT',
}

export const stateOptions = [
    {
        "key": "AN",
        "name": "Andaman and Nicobar Islands"
    },
    {
        "key": "AP",
        "name": "Andhra Pradesh"
    },
    {
        "key": "AR",
        "name": "Arunachal Pradesh"
    },
    {
        "key": "AS",
        "name": "Assam"
    },
    {
        "key": "BR",
        "name": "Bihar"
    },
    {
        "key": "CG",
        "name": "Chandigarh"
    },
    {
        "key": "CH",
        "name": "Chhattisgarh"
    },
    {
        "key": "DH",
        "name": "Dadra and Nagar Haveli"
    },
    {
        "key": "DD",
        "name": "Daman and Diu"
    },
    {
        "key": "DL",
        "name": "Delhi"
    },
    {
        "key": "GA",
        "name": "Goa"
    },
    {
        "key": "GJ",
        "name": "Gujarat"
    },
    {
        "key": "HR",
        "name": "Haryana"
    },
    {
        "key": "HP",
        "name": "Himachal Pradesh"
    },
    {
        "key": "JK",
        "name": "Jammu and Kashmir"
    },
    {
        "key": "JH",
        "name": "Jharkhand"
    },
    {
        "key": "KA",
        "name": "Karnataka"
    },
    {
        "key": "KL",
        "name": "Kerala"
    },
    {
        "key": "LD",
        "name": "Lakshadweep"
    },
    {
        "key": "MP",
        "name": "Madhya Pradesh"
    },
    {
        "key": "MH",
        "name": "Maharashtra"
    },
    {
        "key": "MN",
        "name": "Manipur"
    },
    {
        "key": "ML",
        "name": "Meghalaya"
    },
    {
        "key": "MZ",
        "name": "Mizoram"
    },
    {
        "key": "NL",
        "name": "Nagaland"
    },
    {
        "key": "OR",
        "name": "Odisha"
    },
    {
        "key": "PY",
        "name": "Puducherry"
    },
    {
        "key": "PB",
        "name": "Punjab"
    },
    {
        "key": "RJ",
        "name": "Rajasthan"
    },
    {
        "key": "SK",
        "name": "Sikkim"
    },
    {
        "key": "TN",
        "name": "Tamil Nadu"
    },
    {
        "key": "TS",
        "name": "Telangana"
    },
    {
        "key": "TR",
        "name": "Tripura"
    },
    {
        "key": "UP",
        "name": "Uttar Pradesh"
    },
    {
        "key": "UK",
        "name": "Uttarakhand"
    },
    {
        "key": "WB",
        "name": "West Bengal"
    }
]

export const permissionOptions = [
    {
        "value": "CATEGORY_PERMISSION",
        "label": "Category | Add, Edit, Delete"
    },
    {
        "value": "PRODUCT_PERMISSION",
        "label": "Product | Add, Edit, Delete"
    },
    {
        "value": "IMPORT_PERMISSION",
        "label": "Lead Import"
    },
    {
        "value": "OWNER_CHANGE_PERMISSION",
        "label": "Owner Change Permission"
    },
    {
        "value": "LEADS_PERMISSION",
        "label": "Lead | Edit, Delete"
    },
    {
        "value": "SERVICE_PERMISSION",
        "label": "Service | Edit, Delete"
    },
    {
        "value": "SERVICE_ADD_PERMISSION",
        "label": "Add a New Service"
    },
    {
        "value": "STAFF_PERMISSION",
        "label": "User | Add, Edit, Delete"
    },
]

export const userSegrigation = [
    {
        "key": "ADMIN",
        "name": "admin"
    },
    {
        "key": "FLOOR MANAGER",
        "name": "floor_manager"
    },
    {
        "key": "TEAM LEADER",
        "name": "team_leader"
    },
    {
        "key": "SALES EXECUTIVE",
        "name": "sales_executive"
    },
    {
        "key": "OPERATION MANAGER",
        "name": "operation_manager"
    },
    {
        "key": "RENEWAL COORDINATOR",
        "name": "renewal_coordinator"
    },
    {
        "key": "SERVICE SUPPORT",
        "name": "service_support"
    },
    {
        "key": "TSO",
        "name": "tso"
    },
]

export const Months = [
    { key: 'January', value: 'January' },
    { key: 'February', value: 'February' },
    { key: 'March', value: 'March' },
    { key: 'April', value: 'April' },
    { key: 'May', value: 'May' },
    { key: 'June', value: 'June' },
    { key: 'July', value: 'July' },
    { key: 'August', value: 'August' },
    { key: 'September', value: 'September' },
    { key: 'October', value: 'October' },
    { key: 'November', value: 'November' },
    { key: 'December', value: 'December' },
]

export const companyOptions = [
    { label: 'SBI', value: 'SBI' },
    { label: 'STAR', value: 'STAR' },
    { label: 'CARE', value: 'CARE' },
    { label: 'ADITYA BIRLA', value: 'ADITYA BIRLA' },
    { label: 'HDFC', value: 'HDFC' },
    { label: 'LIBERTY', value: 'LIBERTY' },
    { label: 'IFCCO', value: 'IFCCO' },
]
export const productOptions = [
    { label: 'AROGYA PLUS', value: 'AROGYA PLUS' },
    { label: 'AROGYA SUPREME', value: 'AROGYA SUPREME' },
    { label: 'AROGYA TOP UP', value: 'AROGYA TOP' },
    { label: 'SUPERHEALTH', value: 'SUPERHEALTH' },
    { label: 'Individual Personal Accident', value: 'Individual Personal Accident' },
    { label: 'Health Individual Indemnity', value: 'Health Individual Indemnity' },
    { label: 'Long Term Home', value: 'Long Term Home' },
    { label: 'SANJEEVANI', value: 'SANJEEVANI' },

]
export const sumOptions = [
    { label: '300000', value: '300000' },
    { label: '500000', value: '500000' },
    { label: '700000', value: '700000' },
    { label: '1000000', value: '1000000' },
    { label: '1500000', value: '1500000' },
    { label: '2000000', value: '2000000' },
    { label: '2500000', value: '2500000' },
    { label: '3000000', value: '3000000' },
    { label: '5000000', value: '5000000' },
    { label: '10000000', value: '10000000' },
]
export const planOptions = [
    { label: 'INDIVIDUAL', value: 'INDIVIDUAL' },
    { label: '2A', value: '2A' },
    { label: '2A+1C', value: '2A+1C' },
    { label: '2A+2C', value: '2A+2C' },
]
export const leadCategoryOptions = [
    { label: 'HEALTH', value: 'HEALTH' },
    { label: 'MOTOR', value: 'MOTOR' },
    { label: 'LIFE', value: 'LIFE' },
]

export const callDetailsWebsite = "http://moneybugs.in/"
export const callDetailsNumber = "9755040311"
