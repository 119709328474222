import React, { useState, useEffect } from 'react'
import Ripples from 'react-ripples';
import { useColorStore } from '../../../../Store/ColorStore';
import { Icon } from '@iconify/react';
import UsersList from './UsersList';
import { useToasts } from 'react-toast-notifications';
import { useUserStore } from '../../../../Store/UserStore/UserStore';
import Loading from '../../../../Loading';
import ReactPaginate from 'react-paginate';
import Select, { useStateManager } from 'react-select';
import makeAnimated from 'react-select/animated';
import { userSegrigation } from '../../../../Export';
const animatedComponents = makeAnimated();

const Users = () => {

  const { addToast } = useToasts();
  const { users, fetchUsers, createUsers } = useUserStore()
  const { color } = useColorStore()

  const [isLoading, setIsLoading] = useState(false)
  const [payload, setPayload] = useState({
    name: '',
    email: '',
    contact: '',
    password: '',
    user_type: '',
    reporting_to: '',
    teleforce_id: '',
  })

  const [error, setError] = useState(false)

  const [payloadError, setPayloadError] = useState({
    email: '',
    contact: '',
    password: '',
  })

  const handleSubmit = () => {
    if (payload.name.length === 0 || payload.email.length === 0 || payload.contact.length === 0 || payload.password.length === 0 || payload.user_type.length === 0) {
      setError(true)
    } else {
      setError(false)
      setIsLoading(true)
      createUsers(payload)
        .then(res => {
          fetchUsers()
          setPayload({
            ...payload, email: '',
            contact: '',
            password: '',
            user_type: '',
            reporting_to: '',
            teleforce_id: '',
          })
          setIsLoading(false)
          addToast('Success...', { appearance: 'success' });
        }).catch(err => {
          console.log(err);
          setIsLoading(false)
          if (err.response.status === 400) {
            addToast(err.response.data, { appearance: 'error' });
          } else {
            addToast('Error...', { appearance: 'error' });
          }
        })

    }
  }

  const [cc, setcc] = useState()

  useEffect(() => {
    fetchUsers()
    setcc(document.getElementById("canvaCloseId"))
    // console.log(cc.click);
  }, [])

  const [searchInput, setSearchInput] = useState("")
  const [searchParam] = useState(["name", 'email', 'contact', 'user_type']);

  function search(items) {
    return items.filter((item) => {
      return searchParam.some((newItem) => {
        return (
          item[newItem] ? item[newItem]
            .toString()
            .toLowerCase()
            .indexOf(searchInput.toLowerCase()) > -1
            : null
        );
      });
    });
  }
  // --------------------------------Filteration work---------------------------------------

  const [filterMode, setFilterMode] = useState(false)
  const [filterData, setFilterData] = useState([])
  const [filterError, setFilterError] = useState(false)
  const [userType, setUserType] = useState([])
  const [activeFilter, setActiveFilter] = useState("")
  const [options, setOptions] = useState([])

  useEffect(() => {
    setOptions([])
    let d = []
    for (let i = 0; i < users.length; i++) {
      const u = users[i];
      let e = d.find((f) => f.value === u.user_type)
      if (!e) {
        d.push({
          value: u.user_type, label: u.user_type
        })
      }
    }
    setOptions(d)
  }, [users])

  const handleFilter = () => {
    if (userType.length > 0 && activeFilter.length > 0) {
      let data = []
      let d = []
      for (let i = 0; i < userType.length; i++) {
        const element = userType[i];
        d.push(element.value)
      }
      data = users.filter((user) => {
        return d.indexOf(user.user_type) > -1;
      });
      data = data.filter((f) => {
        if (activeFilter === 'A') {
          return f.is_active
        } else if (activeFilter === 'I') {
          return !f.is_active
        }
      })
      setFilterData(data)
    }
    else if (userType.length > 0 && activeFilter.length === 0) {
      console.log('2')
      let data = []
      let d = []
      for (let i = 0; i < userType.length; i++) {
        const element = userType[i];
        d.push(element.value)
      }
      data = users.filter((user) => {
        return d.indexOf(user.user_type) > -1;
      });
      setFilterData(data)
    }
    else if (userType.length === 0 && activeFilter.length > 0) {
      let data = []
      data = users.filter((f) => {
        if (activeFilter === 'A') {
          return f.is_active
        } else if (activeFilter === 'I') {
          return !f.is_active
        }
      })
      setFilterData(data)
    }
  }

  useEffect(() => {
    if (activeFilter.length > 0 || userType.length > 0) {
      setFilterMode(true)
      handleFilter()
    } else {
      setFilterMode(false)
      setFilterData([])
    }
  }, [activeFilter, userType, users])

  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: color.inputBackground,
      // match with the menu
      borderRadius: 4,
      // Overwrittes the different states of border
      borderColor: color.borderColor,
      fontSize: 14,
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: color.borderColor
      }
    }),
    menu: base => ({
      ...base,
      background: color.inputBackground,
      color: color.darkText,
      fontSize: 14,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isFocused ? color.blue : null,
        color: isFocused ? "#fff" : null,
        // color: "#333333"
      };
    }
  };

  const clearFilter = () => {
    setActiveFilter("")
    setUserType([])
  }

  const handleRemoveUserType = (index) => {
    setUserType([
      ...userType.slice(0, index),
      ...userType.slice(index + 1, userType.length)
    ]);
  }


  // --------------------Pagination Works------------------------------

  // Pagination Work Start

  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [pageCount, setPageCount] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    if (filterMode) {
      const endOffset = itemOffset + itemsPerPage;
      // console.log(`Loading items from ${itemOffset + 1} to ${endOffset}`);
      setCurrentItems(filterData.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(filterData.length / itemsPerPage));
    } else {
      // Fetch items from another resources.
      const endOffset = itemOffset + itemsPerPage;
      // console.log(`Loading items from ${itemOffset + 1} to ${endOffset}`);
      setCurrentItems(users.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(users.length / itemsPerPage));
    }

  }, [itemOffset, itemsPerPage, users, filterData]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % users.length;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`
    // );
    setItemOffset(newOffset);
  };

  return (
    <>
      <div className="category_body" style={{ backgroundColor: color.secondaryBg }}>
        <div className="myrow justify_space_between mb-4">
          <div className="ico-search" style={{ position: 'relative' }}>
            <input type="text" placeholder='Search...' value={searchInput} onChange={(e) => setSearchInput(e.target.value)} className='localSearchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder }} />
            <span style={{ color: color.darkText }}><Icon icon="ion:search-outline" style={{ color: color.darkText }} fontSize={16} /></span>
          </div>
          <div className="myrow">
            <Ripples color='rgba(255,255,255,0.2)' className='me-3' during={800}>
              <button className="infoButton" data-bs-toggle="offcanvas" data-bs-target="#ProductFilterCanvas" aria-controls="ProductFilterCanvas" >Filter</button>
            </Ripples>
            <Ripples color='rgba(255,255,255,0.2)' during={1000}>
              <button className="primaryButton" data-bs-toggle="modal" data-bs-target="#categoryModal" >Create User</button>
            </Ripples>
          </div>
        </div>

        {filterMode &&
          <div className='filter_values_wrapper'>
            <div className="filter_values_title" style={{ color: color.darkText }}>Filter by &nbsp;:<span onClick={clearFilter} className="clear_filter_link">Clear filter</span></div>
            {activeFilter.length > 0 && <div className="filter_values">{activeFilter === "A" ? 'Active' : 'In-Active'} <Icon icon="fa6-solid:x" onClick={() => setActiveFilter("")} type="button" fontSize={16} style={{ paddingLeft: '5px' }} /></div>}
            {userType.length > 0 ?
              userType.map((u, count) => {
                return <div className="filter_values" key={count}>{u.value.toLowerCase()} <Icon icon="fa6-solid:x" onClick={() => handleRemoveUserType(count)} type="button" fontSize={16} style={{ paddingLeft: '5px' }} /></div>
              })
              : null
            }

          </div>
        }

        {/* ------------------------------Table Work-------------------------------------- */}

        <div className="table-responsive">
          <div style={{ color: color.darkText, fontSize: 12, marginBottom: '10px' }}>
            Show Entries - <input type="text" defaultValue="10" onChange={(e) => e.target.value === 0 || e.target.value === '0' ? setItemsPerPage() : setItemsPerPage(parseInt(e.target.value))} className='searchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder, width: '35px', padding: '5px', textAlign: 'center', borderRadius: '2px' }} />
          </div>
          <table className="table table-nowrap align-middle mb-3 table-bordered table-striped">
            <thead style={{ backgroundColor: color.tableHeader }}>
              <tr style={{ color: color.darkText }}>
                <th scope="col" style={{ width: '50px', color: color.darkText }}>
                  {/* <div className="">
                    <input type="checkbox" className="form-check-input" style={{ backgroundColor: color.inputBackground, border: "1px solid " + color.borderColor }} />
                    <label className="form-check-label" for="contacusercheck"></label>
                  </div> */}
                  S.No.
                </th>
                <th style={{ color: color.darkText }} scope="col">Name</th>
                <th style={{ color: color.darkText }} scope="col">Contact Info</th>
                <th style={{ color: color.darkText }} scope="col">Teleforce User ID</th>
                <th style={{ color: color.darkText }} scope="col">Status</th>
                <th style={{ color: color.darkText }} scope="col">Role</th>
                <th style={{ color: color.darkText }} scope="col">Reporting To</th>
                <th scope="col" style={{ width: '200px', color: color.darkText }}>Action</th>
              </tr>
            </thead>

            <tbody>
              {users.length > 0 && !filterMode ?
                searchInput.length > 0 ?
                  search(users).map((c, count) => {
                    return <UsersList data={c} cc={cc} key={c.id} count={itemOffset + count + 1} setIsLoading={setIsLoading} isLoading={isLoading} />
                  })
                  :
                  currentItems.map((c, count) => {
                    return <UsersList data={c} cc={cc} key={c.id} count={itemOffset + count + 1} setIsLoading={setIsLoading} isLoading={isLoading} />
                  })
                :
                null}

              {filterMode ?
                filterData.length > 0 ?
                  currentItems.map((c, count) => {
                    return <UsersList data={c} cc={cc} key={c.id} count={itemOffset + count + 1} setIsLoading={setIsLoading} isLoading={isLoading} />
                  })
                  : <div style={{ color: color.darkText, fontSize: 14, textAlign: 'center' }}>No Record to Show....!</div>
                :
                null}

            </tbody>
          </table>

          {searchInput.length > 0 ? null :
            <ReactPaginate
              nextLabel=">>"
              onPageChange={handlePageClick}
              pageRangeDisplayed={2}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel="<<"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="pagination-active"
              renderOnZeroPageCount={null}
            />
          }
        </div>
      </div>
      {isLoading &&
        <Loading />}
        
      {/* ----------------------Add Modal---------------------------- */}

      <div className="modal fade" id="categoryModal" tabIndex="-1" aria-labelledby="categoryModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>
            <div className="modal-header" style={{ borderBottomColor: color.tableBorder }}>
              <div className="modal_title" style={{ color: color.darkText }} id="categoryModalLabel">Create User</div>
              <span type="button" className='modal_close' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
            </div>
            <div className="modal-body" style={{ borderBottomColor: color.tableBorder }}>

              {error && <div className='text-danger' style={{ fontSize: '12px', marginTop: '-2px', marginBottom: '5px' }}>All fields Required...!</div>}

              <div className='mb-2'>
                <label className='modal_label' style={{ color: color.darkText }}>Name <span className="text-danger">*</span></label>
                <input placeholder='Enter Name' value={payload.name} onChange={(e) => setPayload({ ...payload, name: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
              </div>

              <div className='mb-2'>
                <label className='modal_label' style={{ color: color.darkText }}>Email <span className="text-danger">*</span></label>
                <input placeholder='Enter Email' value={payload.email} onChange={(e) => setPayload({ ...payload, email: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
              </div>

              <div className='mb-2'>
                <label className='modal_label' style={{ color: color.darkText }}>Contact <span className="text-danger">*</span></label>
                <input placeholder='Enter Phone' value={payload.contact} onChange={(e) => setPayload({ ...payload, contact: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
              </div>

              <div className='mb-2'>
                <label className='modal_label' style={{ color: color.darkText }}>Teleforce User Id</label>
                <input placeholder='Enter Phone' value={payload.teleforce_id} onChange={(e) => setPayload({ ...payload, teleforce_id: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
              </div>

              <div className='mb-2'>
                <label className='modal_label' style={{ color: color.darkText }}>Password <span className="text-danger">*</span></label>
                <input placeholder='Enter Password' value={payload.password} onChange={(e) => setPayload({ ...payload, password: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
              </div>

              <div className='mb-2'>
                <label className='modal_label' style={{ color: color.darkText }}>User Type <span className="text-danger">*</span></label>
                <select value={payload.user_type} onChange={(e) => setPayload({ ...payload, user_type: e.target.value.toUpperCase() })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                  <option value="" disabled>----------Select User Type-------------</option>
                  {userSegrigation.map((d)=>{
                    return <option key={d.key} value={d.key}>{d.key}</option>
                  })}
                </select>
              </div>

              <div className='mb-2'>
                <label className='modal_label' style={{ color: color.darkText }}>Reporting To</label>
                <select value={payload.reporting_to} onChange={(e) => setPayload({ ...payload, reporting_to: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                  <option value="">----------Select Reporting To-------------</option>
                  {users.map((d) => {
                    return <option value={d.id} key={d.id}>{d.name}</option>
                  })}
                </select>
              </div>

            </div>
            <div className="modal-footer" style={{ borderTopColor: color.tableBorder }}>
              <button type="button" style={{ color: color.darkText }} className="lightButton" data-bs-dismiss="modal">Close</button>
              <button type="button" className="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleSubmit}>{isLoading ?
                <>
                  <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                  Please wait...
                </>

                : "Create"}</button>
            </div>
          </div>
        </div>
      </div>


      {/* ------------------------------Filter modal----------------------------------- */}

      <div className="offcanvas offcanvas-end" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }} tabIndex="-1" id="ProductFilterCanvas" aria-labelledby="offcanvasRightLabel">
        <div className="modal-content">
          <div className="modal-header px-3 pt-3" style={{ borderBottomColor: color.tableBorder }}>
            <div className="modal_title" style={{ color: color.darkText }} id="categoryModalLabel">Filter User</div>
            <span type="button" id='canvaCloseId' className='modal_close' data-bs-dismiss="offcanvas" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>

          </div>
        </div>
        <hr />
        <div className="offcanvas-body">
          <div className="mb-3">
            <label className='modal_label' style={{ color: color.darkText }}>Status</label>
            <select value={activeFilter} onChange={(e) => setActiveFilter(e.target.value)} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'  >
              <option value="">-------Select Status-------</option>
              <option value={'A'}>Active</option>
              <option value={"I"}>In-Active</option>

            </select>
          </div>

          <div className="mb-4">
            <label className='modal_label' style={{ color: color.darkText }}>User Type</label>
            {/* <select style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' >
              <option value="">-------Select User Type-------</option> */}
            <Select
              styles={customStyles}
              closeMenuOnSelect={false}
              components={animatedComponents}
              isMulti
              options={options}
              onChange={(e) => setUserType(e)}
              value={userType}
            />
            {/* {users.map((c) => {
                return <option value={c.user_type} key={c.id}>{c.user_type}</option>
              })} */}
            {/* </select> */}
          </div>

          <div className="pt-4">

            <button className="secondaryButton" onClick={clearFilter}>Clear filter</button>

          </div>
        </div>
      </div>
    </>
  )
}

export default Users