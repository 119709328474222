import { Avatar } from '@mui/material'
import React, { useState } from 'react'
import { fileUrl } from '../../../../../Export'
import { Icon } from '@iconify/react'
import { useUserStore } from '../../../../../Store/UserStore/UserStore'
import { useToasts } from 'react-toast-notifications'

const Profile = (props) => {
  const { addToast } = useToasts();
  const { currentUser, color, setIsLoading, isLoading, permission } = props
  const [error, setError] = useState(false)
  const [error2, setError2] = useState(false)
  const { users, fetchUsers, changePassword, updateUsers, removeProfilePic, changeProfilePic } = useUserStore()

  const [payload, setPayload] = useState({
    password: ''
  })

  const [payload2, setPayload2] = useState({
    name: currentUser.name,
    email: currentUser.email,
    contact: currentUser.contact,
    user_type: currentUser.user_type,
    reporting_to: currentUser.user_related.length > 0 ? currentUser.user_related[0].to : '',
  })

  const handleSubmit = () => {
    if (payload.password.length < 6) {
      setError(true)
    }
    else {
      setError(false)
      setIsLoading(true)
      changePassword(payload, currentUser.id)
        .then(res => {
          setPayload({ ...payload, password: '' })
          setIsLoading(false)
          addToast('Success...', { appearance: 'success' });
        })
        .catch(err => {
          console.log(err);
          setIsLoading(false)
          addToast('Error...', { appearance: 'error' });
        })
    }
  }

  const handleUpdate = () => {
    if (payload2.name.length === 0 || payload2.email.length === 0 || payload2.contact.length === 0 || payload2.user_type.length === 0) {
      setError2(true)
    } else {
      setError2(false)
      setIsLoading(true)
      updateUsers(payload2, currentUser.id)
        .then(res => {
          fetchUsers()
          setIsLoading(false)
          setPayload({
            ...payload2, name: payload2.name,
            email: payload2.email,
            contact: payload2.contact,
            password: payload2.password,
            user_type: payload2.user_type,
            reporting_to: payload2.reporting_to,
          })
          addToast('Success...', { appearance: 'success' });
        }).catch(err => {
          console.log(err);
          setIsLoading(false)
          addToast('Error...', { appearance: 'error' });
        })
    }
  }

  const handleRemoveProfilePic = () => {
    let d = document.getElementById("RmPic")
    d.click()
    setIsLoading(true)
    removeProfilePic(currentUser.id)
      .then(res => {
        addToast('Success...', { appearance: 'success' });
        setIsLoading(false)
        fetchUsers()
      })
      .catch(err => {
        setIsLoading(false)
        addToast('Error...', { appearance: 'error' });
      })
  }

  const [proimg, setProimg] = useState()

  const handleChangeProfilePic = () => {
    if (proimg) {
      let d = document.getElementById("ChangePicClose")
      d.click()
      setIsLoading(true)
      changeProfilePic(currentUser.id, proimg)
        .then(res => {
          addToast('Success...', { appearance: 'success' });
          setIsLoading(false)
          setProimg()
          fetchUsers()
        })
        .catch(err => {
          setIsLoading(false)
          addToast('Error...', { appearance: 'error' });
        })
    }
  }

  return (
    <>
      <div className="d-flex justify_space_between">
        <div>
          <Avatar
            sx={{ color: "#fff", bgcolor: "#0C768A", fontSize: 40, fontWeight: 500, width: '80px', height: '80px', margin: '' }}
            alt={currentUser.name}
            src={fileUrl + currentUser.proimg}
            data-bs-toggle={currentUser.proimg ? "modal" : ''} data-bs-target={currentUser.proimg ? "#showProfilePic" : ''}
            type={currentUser.proimg ? "button" : ''}
          />

          <div style={{ fontSize: '12px', color: color.blue2, padding: '10px' }}>
            <span data-bs-toggle="modal" data-bs-target="#changeProfilePic" style={{ color: color.blue2, cursor: 'pointer' }}> Change </span> |
            <span data-bs-toggle="modal" data-bs-target="#RemoveProfilePic" style={{ color: color.blue2, cursor: 'pointer' }}> Remove</span>
          </div>

          <div className="m-2">
            <div style={{ color: color.darkText, fontSize: '24px', fontWeight: 500 }}>{currentUser.name}</div>
            <div style={{ color: color.lightText, fontSize: '16px', fontWeight: 500, textTransform: 'capitalize' }}>{currentUser.user_type.toLowerCase()}</div>
          </div>

        </div>

        <div>
          <button className="dangerButton" data-bs-toggle="modal" data-bs-target="#changePasswordModal" >Change Password</button>
        </div>

      </div>

      {/* ------------------------------------------------Update Work--------------------------------------------- */}
      <div className="mt-4">
        <div className="modal-body" style={{ borderBottomColor: color.tableBorder }}>

          {error2 && <div className='text-danger' style={{ fontSize: '12px', marginTop: '-2px', marginBottom: '5px' }}>All fields Required...!</div>}

          <div className='mb-2'>
            <label className='modal_label' style={{ color: color.darkText }}>Name <span className="text-danger">*</span></label>
            <input placeholder='Enter Name' disabled={!permission} value={payload2.name} onChange={(e) => setPayload2({ ...payload2, name: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
          </div>

          <div className='mb-2'>
            <label className='modal_label' style={{ color: color.darkText }}>Email <span className="text-danger">*</span></label>
            <input placeholder='Enter Email' disabled={!permission} value={payload2.email} onChange={(e) => setPayload2({ ...payload2, email: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
          </div>

          <div className='mb-2'>
            <label className='modal_label' style={{ color: color.darkText }}>Contact <span className="text-danger">*</span></label>
            <input placeholder='Enter Phone' disabled={!permission} value={payload2.contact} onChange={(e) => setPayload2({ ...payload2, contact: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
          </div>

          <div className='mb-2'>
            <label className='modal_label' style={{ color: color.darkText }}>User Type <span className="text-danger">*</span></label>
            <select value={payload2.user_type} disabled={!permission} onChange={(e) => setPayload2({ ...payload2, user_type: e.target.value.toUpperCase() })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>

              <option value="ADMIN" disabled>ADMIN</option>
              <option value="SUB ADMIN">SUB ADMIN</option>
              <option value="RESEARCHER">RESEARCHER</option>
              <option value="SALES MANAGER">SALES MANAGER</option>
              <option value="SALES EXECUTIVE">SALES EXECUTIVE</option>
              <option value="COMPLIANCE">COMPLIANCE</option>
              <option value="HR">HR</option>
            </select>
          </div>

          <div className='mb-2'>
            <label className='modal_label' style={{ color: color.darkText }}>Reporting To</label>
            <select value={payload2.reporting_to} disabled={!permission} onChange={(e) => setPayload2({ ...payload2, reporting_to: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
              <option value="">----------Select Reporting To-------------</option>
              {users.map((d) => {
                return <option value={d.id} key={d.id}>{d.name}</option>
              })}
            </select>
          </div>

        </div>

        {permission &&
          <div className=" pt-4 pb-3" style={{ borderTopColor: color.tableBorder }}>
            <button type="button" className="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleUpdate}>{isLoading ?
              <>
                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                Please wait...
              </>

              : "Update"}</button>
          </div>
        }
      </div>


      {/* --------------Password Change Modal--------------------------- */}

      <div className="modal fade" id="changePasswordModal" tabInex="-1" aria-labelledby="changePasswordModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>
            <div className="modal-header" style={{ borderBottomColor: color.tableBorder }}>
              <div className="modal_title" style={{ color: color.darkText }} id="changePasswordModalLabel">Change Password</div>
              <span type="button" className='modal_close' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
            </div>
            <div className="modal-body" style={{ borderBottomColor: color.tableBorder }}>

              {error && <div className='text-danger' style={{ fontSize: '12px', marginTop: '-2px', marginBottom: '5px' }}>Password length must be 6...!</div>}

              <div className='mb-3'>
                <label className='modal_label' style={{ color: color.darkText }}>New Password <span className="text-danger">*</span></label>
                <input placeholder='Enter Password' value={payload.password} onChange={(e) => setPayload({ ...payload, password: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
              </div>
            </div>
            <div className="modal-footer" style={{ borderTopColor: color.tableBorder }}>
              <button type="button" style={{ color: color.darkText }} className="lightButton" data-bs-dismiss="modal">Close</button>
              <button type="button" className="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleSubmit}>{isLoading ?
                <>
                  <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                  Please wait...
                </>
                : "Change"}</button>
            </div>
          </div>
        </div>
      </div>

      {/* ------------------------------Remove Profile Pic modal----------------------------------- */}

      <div className="modal fade" id={"RemoveProfilePic"} tabIndex="-1" aria-labelledby="RemoveProfilePicLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>

            <div className="modal-body" style={{ borderBottomColor: color.tableBorder }}>
              <div className="modal_title" style={{ color: color.darkText }}>Are you sure?</div>

              <div className='pt-3' style={{ color: color.lightText, fontSize: '15px' }}>Do you really want to Remove Profile Picture?</div>
            </div>
            <div className="modal-footer" style={{ borderTopColor: color.tableBorder }}>
              <button type="button" id="RmPic" style={{ color: color.darkText }} className="lightButton" data-bs-dismiss="modal">Close</button>
              <button type="button" className="dangerButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleRemoveProfilePic}>{isLoading ?
                <>
                  <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                  Please wait...
                </>
                : "Proceed"}</button>
            </div>
          </div>
        </div>
      </div>

      {/* --------------Profile Pic Change Modal--------------------------- */}

      <div className="modal fade" id="changeProfilePic" tabInex="-1" aria-labelledby="changeProfilePicLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>
            <div className="modal-header" style={{ borderBottomColor: color.tableBorder }}>
              <div className="modal_title" style={{ color: color.darkText }} id="changeProfilePicLabel">Add/Change Profile Pic</div>
              <span type="button" className='modal_close' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
            </div>
            <div className="modal-body" style={{ borderBottomColor: color.tableBorder }}>
              <div className='mb-3'>
                <label className='modal_label' style={{ color: color.darkText }}>Upload Image <span className="text-danger">*</span></label>
                <input type='file' onChange={(e) => setProimg(e.target.files[0])} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
              </div>
            </div>
            <div className="modal-footer" style={{ borderTopColor: color.tableBorder }}>
              <button type="button" id='ChangePicClose' style={{ color: color.darkText }} className="lightButton" data-bs-dismiss="modal">Close</button>
              <button type="button" className="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleChangeProfilePic}>{isLoading ?
                <>
                  <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                  Please wait...
                </>
                : "Upload"}</button>
            </div>
          </div>
        </div>
      </div>

      {/* --------------Profile Pic Show Modal--------------------------- */}

      <div className="modal fade" id="showProfilePic" tabInex="-1" aria-labelledby="showProfilePicLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="text-end p-2">
            <span type="button" className='modal_close' style={{ opacity: 1 }} data-bs-dismiss="modal" aria-label="Close"><Icon icon="icon-park-solid:big-x" fontSize={20} color={color.darkText} /></span>
          </div>
          <div className="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>
            <img src={currentUser.proimg} alt={currentUser.name} />
          </div>
        </div>
      </div>

    </>
  )
}

export default Profile